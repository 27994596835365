<template>
  <div>
    <div class="header">
      <div v-for="(item, index) in items" :key="index">
        <img :src="item.icon">
        <span :class="{ 'ml-10': item.icon }">{{ item.label }}</span>
      </div>
    </div>
    <div class="table-box">
      <b-form-checkbox-group v-model="checkItems" plain>
        <div v-for="(item, index) in tableList" :key="index" class="table-item">
          <span>{{ item.date }}</span>
          <span v-if="category.includes(country)">{{ item.category }}</span>
          <span>{{ item.currency }}</span>
          <span>{{ item.trend }}</span>
        </div>
      </b-form-checkbox-group>
    </div>
  </div>
</template>
<script>

export default {
  name: 'BtcDataTable',
  components: {},
  props: {
    country: {
      type: String,
      default: () => ''
    },
    tableList: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      checkItems: [],
      category: ['澳大利亚', '乌拉圭', '阿根廷']
    }
  },
  methods: {
    // handleCountry (index) {
    //   if (index === 0) {
    //     return 'Steeres'
    //   } else if (index === 1) {
    //     return 'Cow'
    //   }
    // }
  }
}
</script>
<style lang="scss" scoped>
$cell-width: 200px;
.selector-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selector-left {
  display: flex;
  justify-content: space-between;
}
// 表头
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
}

.header > div {
  min-width: $cell-width;
  text-align: center;
}

.ml-10 {
  margin-left: 10px;
}

.table-box {
  margin-top: 10px;
  height: 400px;
  overflow-y: scroll;
}

.table-item {
  height: 35px;
  border-radius: 8px;
  background: #f1f2f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
}

.table-item > span {
  width: $cell-width;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
</style>
